export default class Pagination {
  constructor(take = 5) {
    this.count = 0;
    this.take = take;
    this.page = 1;

    this.complete = false;
  }
  get length() {
    return Math.ceil(this.count / this.take);
  }

  get skip() {
    return this.take * (this.page - 1);
  }
}
