<template>
  <div>
    <!-- 無限スクロール使用時 -->
    <!-- <v-app-bar
      class="mt-2"
      flat
      dense
      app
      style="top: 50px"
      color="transparent"
      height="20"
    >
      <v-toolbar-title>
        <v-subheader>
          <v-icon v-text="`mdi-camera-outline`" left small />
          <span>Lates shots</span>
          <span class="ml-2">{{
            `${shortCodes.length} / ${pagination.count}`
          }}</span>
        </v-subheader>
      </v-toolbar-title>
      <v-spacer />
      <v-fade-transition>
        <v-sheet v-if="!pagination.complete" width="150">
          <v-progress-linear
            :value="Math.floor((shortCodes.length / pagination.count) * 100)"
            height="5"
          ></v-progress-linear> </v-sheet
      ></v-fade-transition>
    </v-app-bar> -->

    <v-container fluid>
      <v-row>
        <v-col
          :cols="12"
          xl="2"
          lg="3"
          md="4"
          sm="6"
          v-for="(shortCode, index) of shortCodes"
          :key="index"
        >
          <v-card outlined>
            <v-list-item dense :href="getHref(shortCode.shortCode)">
              <v-list-item-avatar
                v-if="shortCode.content.author.avatar"
                color="grey"
                size="25"
              >
                <v-img :src="shortCode.content.author.avatar" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-if="shortCode.content.author.name"
                  class="ma-0 text-caption"
                >
                  <span> {{ shortCode.content.author.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="shortCode.content.author.link"
                  class="ma-0 text-caption"
                >
                  {{ shortCode.content.author.link }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                  <span class="text-caption">
                    {{ unixTimeToDate(shortCode.date) | fromNow }}
                  </span>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>

            <v-card
              class="rounded-b-0"
              elevation="0"
              :href="getHref(shortCode.shortCode)"
            >
              <v-img :src="shortCode.content.src" height="150" />

              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title class="ma-0 text-caption">
                    <a
                      class="text-decoration-none"
                      :href="shortCode.content.url"
                      target="_blank"
                    >
                      {{ shortCode.content.title }}
                    </a>
                  </v-list-item-title>
                  <v-list-item-subtitle class="ma-0 text-caption">
                    {{ shortCode.content.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>

            <v-divider />

            <v-card-actions class="pa-1">
              <!-- 単行で文字数超えたら点々を出す -->

              <div class="text-caption col-12 text-truncate pa-0">
                {{
                  $options.filters.md(shortCode.content.setting.description)
                    | deHtmlTag
                }}&nbsp;
              </div>
              <!-- <div
                class="text-caption col-12 text-truncate pa-0"
                v-html="
                  $options.filters.md(shortCode.content.setting.description)
                "
              ></div> -->
            </v-card-actions>
            <v-card-actions class="pa-2">
              <span
                v-if="shortCode.content.rate.ave"
                class="d-flex text-caption mx-1"
              >
                <v-rating
                  :value="shortCode.content.rate.ave"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>

                <span class="grey--text">
                  {{ Math.round(shortCode.content.rate.ave * 10) / 10 }}
                </span>
              </span>
              <v-spacer />

              <span class="text-caption">
                &nbsp;
                <DisqusCount
                  class="text-caption"
                  :identifier="shortCode.shortCode"
                />
              </span>
            </v-card-actions>
          </v-card>

          <div class="d-flex flex-row-reverse">
            <span class="text-caption">
              {{ unixTimeToDate(shortCode.publishDate) | dateTime }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-pagination
        class="mt-4"
        v-model.number="page"
        :length="pagination.length"
        :total-visible="7"
        @input="onPage"
      ></v-pagination>

      <!-- 無限スクロール -->
      <!-- <InfiniteLoading spinner="spiral" @infinite="infiniteHandler">
        <template v-slot:no-more>
          <div class="mt-4 text-caption">これ以上データはありません</div>
        </template>
        <template v-slot:no-results>
          <div class="mt-4 text-caption">これ以上検索結果はありません</div>
        </template>
      </InfiniteLoading> -->
    </v-container>
  </div>
</template>

<script>
import Pagination from "@/util/Pagination";
const pagination = new Pagination();
pagination.take = 10;

export default {
  name: "Home",
  components: {},

  computed: {
    pagination() {
      return pagination;
    },
  },
  data: () => ({
    initializing: true,

    page: 1,

    //

    // loading: false,
    // page: 0,
    // count: 0,
    // complete: false,
    shortCodes: [],
  }),
  methods: {
    async onLoadAsync() {
      // this.complete = true;
      // this.initializing = false;
      //
      await this.loadPageAsync(1);
    },
    async onPage(page) {
      // 現在と同じページなら何もしない
      if (this.pagination.page == page) return;
      //
      await this.loadPageAsync(page);
    },

    async loadPageAsync(page) {
      this.pagination.page = page;

      try {
        this.initializing = true;

        this.loading = true;

        const success = await this.$application.http.get(
          "shortCode/publishies",
          {
            take: this.pagination.take,
            skip: this.pagination.skip,
          }
        );
        //
        let data = success.data;
        let count = data.count;
        let complete = data.complete;
        let datas = data.datas;
        //
        this.pagination.count = count;
        this.pagination.complete = complete;

        // カードにランダム挿入試作
        // {
        //   const index = Math.floor(Math.random() * datas.length - 1);
        //   datas.splice(index, 0, {
        //     isAdsense: true,
        //   });
        // }

        this.shortCodes = datas;

        //
        // this.shortCodes = response.data;
      } finally {
        this.initializing = false;
        this.loading = false;
      }
    },

    /*
    // 無限スクロール
    // こっちを使う場合はloadPageAsyncをなくすこと
    async loadDatasAsync() {
      //
      // 連続読み込み防止
      // if (this.loading) return;

      try {
        this.initializing = true;

        this.loading = true;

        const success = await this.$application.http.get(
          "shortCode/publishies",
          {
            take: this.pagination.take,
            skip: this.pagination.skip,
          }
        );
        //
        let data = success.data;
        let count = data.count;
        let complete = data.complete;
        let datas = data.datas;
        //
        this.pagination.count = count;
        this.pagination.complete = complete;

        // カードにランダム挿入試作
        // {
        //   const index = Math.floor(Math.random() * datas.length - 1);
        //   datas.splice(index, 0, {
        //     isAdsense: true,
        //   });
        // }

        this.shortCodes = this.shortCodes.concat(datas);
        //
        //
        this.pagination.page++;
        // this.shortCodes = response.data;
      } finally {
        this.initializing = false;
        this.loading = false;
      }
    },

    // 無限スクロール
    async infiniteHandler($state) {
      // 全部ロードし終わっていたら$state.complete(), まだあるなら $state.loaded()
      if (this.pagination.complete) {
        $state.complete();
      } else {
        await this.loadDatasAsync();
        $state.loaded();
        // if (!this.loading) {
        //   await this.onLoadAsync();
        //   $state.loaded();
        // }
        // $state.complete();
      }
    },
    */

    getHref(shortCode) {
      return `/shot/${shortCode}`;
      // this.$router.push(`/shot/${shortCode}`);
    },
  },
  //
  async mounted() {
    this.pagination.count = 0;
    this.pagination.complete = false;
    this.shortCodes = [];
    //
    await this.onLoadAsync();
  },
};
</script>
